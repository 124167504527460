
@import './var.scss'; 

.learning-new {
  .rbt-page-banner-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    // padding: 60px 0px 235px;
    padding: 0px 0px 170px;
    width: 100%;
    overflow: hidden;
  }
  
  .rbt-page-banner-wrapper .rbt-banner-image {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -4;
  }
  
  .rbt-page-banner-wrapper .rbt-banner-image, .bg-gradient-9 {
    background: linear-gradient(270deg, $color-secondary 0%, $color-primary 100%) !important;
    // background: linear-gradient(270deg, #0d6efd 0%, #00b2ff 100%) !important;
    // background: linear-gradient(270deg, rgba(23, 162, 184, 0.5) 0%, #17a2b8 100%) !important;
  
  }
  
  .rbt-page-banner-wrapper .rbt-banner-image::after {
    background: linear-gradient(#f7f8fa 0%, rgba(255, 255, 255, 0.1) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -1;
  }
  
  .align-items-center {
    align-items: center!important;
  }
  
  .rbt-sorting-list {
    margin: -10px -10px;
  }
  
  .d-block {
    display: block!important;
  }
  
  .flex-wrap {
    flex-wrap: wrap!important;
  }
  
  .d-flex {
    display: flex!important;
  }
  
  .mt--40 {
    margin-top: 40px !important;
  }
  
  .rbt-sorting-list .course-switch-layout {
    display: flex;
    align-items: center;
    // background: $white-opacity;
    background: #65595921;
    border-radius: 500px;
    padding: 8px;
    margin: 0;
    list-style: none
  }
  
  .rbt-sorting-list .rbt-short-item {
    margin: 10px 10px;
    font-family: $font-primary;
  }
  
  .rbt-sorting-list .course-switch-layout li button.active {
    background: $color-white;
    color: $color-primary;
  } 
  
  .rbt-sorting-list .course-switch-layout li button {
    background: transparent;
    display: block;
    padding: 7px 16px;
    border-radius: 500px;
    font-size: 16px;
    transition: 0.4s;
    border: 0 none;
    box-shadow: none;
  }
  
  .rbt-sorting-list .course-switch-layout li {
    margin: 0;
  }
  
  ul.course-switch-layout li {
    margin-top: 10px;
    margin-bottom: 10px;
    color: $color-body;
  }
  
  .rbt-search-style input {
    background: transparent;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    padding-right: 60px;
    border-radius: 500px;
    min-width: 300px;
    // border: 2px solid #117a8b;
    // color: #212529;
    border: 2px solid $color-white;
    color: $color-white;
  }
  
  a:hover, a:focus, a:active {
    text-decoration: none;
    outline: none;
    color: $color-primary;
  }
  
  input#searchInput::placeholder {
    color: $color-white;
  }
  
  input[type=text]#searchInput {
    font-size: 16px;
    font-weight: 400;
    font-family: $font-primary;
    outline: none;
    box-shadow: $shadow-10;
  }
  
  input#searchInput, select#custom-select-1 {
    width: 100%;
    transition: 0.3s;
    margin: 0;
    max-width: 100%;
    vertical-align: baseline;
    // font-family: inherit;
    font-family: $font-primary;
  }
  
  
  .rbt-course-top-wrapper .select-label {
    font-family: $font-primary;
    opacity: 0.8;
    color: $color-heading;
  }
  
  .select-label, .rbt-label-style {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    opacity: 0.5;
    margin-bottom: 6px;
  }
  
  
  
  
  .rbt-modern-select .bootstrap-select > select {
    left: 0;
  }
  
  .filter-select select {
    border: 0 none;
    box-shadow: $shadow-1;
    width: auto;
    color: $color-primary;
    min-width: 250px;
  }
  
  .bootstrap-select>select {
    position: absolute!important;
    bottom: 0;
    left: 50%;
    display: block!important;
    width: 0.5px!important;
    height: 100%!important;
    padding: 0!important;
    opacity: 0!important;
    border: none;
    z-index: 0!important;
  }
  
  select {
    transition: 0.3s;
    height: 50px;
    line-height: 28px;
    padding: 10px 20px;
    outline: none;
    color: $color-body;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: $border-width solid $color-border;
    border-radius: $radius;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    padding-right: 30px;
    word-wrap: normal;
    text-transform: none;
    background: #ffffff url(../../assets/theme-assets/images/icons/arrow.png) no-repeat center right 20px;
  }
  
  .rbt-modern-select .bootstrap-select button.btn-light {
    border: 0 none;
    box-shadow: $shadow-1;
    height: 50px;
    padding: 10px 20px;
    outline: none;
    color: $color-body;
    border-radius: $radius;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    padding-right: 30px;
    background-color: #ffffff;
    outline: none;
  } 
  
  .rbt-section-overlayping-top {
    margin: -175px auto 0;
    position: relative;
    z-index: 2;
  }
  
  .rbt-section-gapBottom {
    // padding-bottom: 120px;
    padding-bottom: 40px;
  }
  
  .rbt-course-grid-column {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-top: -30px;
    font-family: $font-primary;
  }
  
  .rbt-course-grid-column .course-grid-3 {
    flex: 0 0 auto;
    width: 33.33%;
  }
  .rbt-course-grid-column .course-grid-3 {
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 30px;
  }
  
  .rbt-card {
    overflow: hidden;
    box-shadow: $shadow-1;
    padding: 30px;
    border-radius: $radius;
    background: $color-white;
    position: relative;
    height: 100%;
  }
  
  .rbt-card.card-list-2 {
    display: flex;
    border-radius: $radius;
    align-items: center;
    height: 100%;
  }
  
  .rbt-course-grid-column .course-grid-3 .rbt-card {
    transition: 0.4s;
  }
  
  .rbt-hover {
    transition: $transition-2;
  }
  
  .rbt-hover:hover {
    transform: scale(1.02);
  }
  
  .rbt-course-grid-column .rbt-card .rbt-card-img {
    transition: none;
  }
  .rbt-card .rbt-card-img {
    position: relative;
  }
  
  
  .rbt-course-grid-column .rbt-card .rbt-card-img a {
    transition: none;
    height: 200px;
  }
  
  .rbt-card .rbt-card-img a {
    display: block;
  }
  
  .rbt-course-grid-column .rbt-card .rbt-card-img a img {
    transition: none;
  }
  
  .rbt-card .rbt-card-img a img {
    width: 100%;
    object-fit: cover;
    border-radius: $radius;
  }
  
  img#custom-image {
    border: 0;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    max-height: 200px;
  }
  
  
  .rbt-card .rbt-card-body {
    padding-top: 30px;
  }
  
  .rbt-card .rbt-card-body .rbt-card-title {
    font-size: 26px;
    margin-bottom: 10px;
    font-weight: $f-bold;  
    font-family: $font-primary;
  }
  
  .rbt-card-title-link {
    color: black;
  }
  
  a.rbt-btn-link:hover::after {
    width: 100%;
    left: 0;
    right: auto;
  }
  
  a.rbt-btn-link::after {
    position: absolute;
    content: "";
    left: auto;
    bottom: 0;
    background: currentColor;
    width: 0;
    height: 2px;
    transition: 0.3s;
    right: 0;
  }
  
  .rbt-card .rbt-card-body .rbt-card-text {
    color: $color-body;
    font-family: $font-primary;
    margin-bottom: 20px;
  }
  
  p.custom-p {
    font-size: 18px;
    line-height: 1.45;
  }
  
  .rbt-card .rbt-card-body .rbt-card-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .rbt-card .rbt-card-body .rbt-card-bottom .rbt-btn-link {
    font-size: 14px;
  }
  
  a.rbt-btn-link {
    color: #192335;
    position: relative;
    transition: 0.3s;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
  }
  
  a.rbt-btn-link i {
    position: relative;
    top: 1px;
    display: inline-block;
    padding-left: 4px;
  }
  
  
  .rbt-course-grid-column.active-list-view .course-grid-3 {
    width: 100%;
  }
  
  .rbt-card.card-list-2 .rbt-card-img {
    flex-basis: 40%;
    height: 100%;
  }
  
  .rbt-card.card-list-2 .rbt-card-img a {
    display: block;
    height: 100%;
    width: 100%;
  }
  
  .rbt-card.card-list-2 .rbt-card-img a img {
    border-radius: $radius;
    max-height: 100%;
    max-width: 100%;
  }
  
  .rbt-card.card-list-2 .rbt-card-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 60%;
    padding-left: 30px;
  }
  
  .rbt-card.card-list-2 .rbt-card-body .rbt-card-title {
    font-size: 26px;
  }
  
  
  
  
  
  
  // bottom card
  
  .rbt-callto-action.callto-action-default {
    position: relative;
    padding: 40px;
    height: 100%;
    background: #ffffff;
    display: flex;
    font-family: $font-primary;
  }
  
  .shadow-1 {
    box-shadow: $shadow-1;
  }
  
  .rbt-radius {
    border-radius: 6px !important;
  }
  
  // .bg-color-white {
  //   background: $color-white;
  // }
  
  .rbt-category {
    margin: 0 -5px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .mb--20 {
    margin-bottom: 20px !important;
  }
  
  .mb--15 {
    margin-bottom: 15px !important;
  }
  
  .mb--10 {
    margin-bottom: 10px !important;
  }
  
  .rbt-category a {
    display: inline-block;
    padding: 0 20px;
    height: 34px;
    line-height: 33px;
    border: 1px solid $color-border;
    border-radius: 500px;
    font-size: 14px;
    font-weight: 400;
    color: $color-body;
    transition: 0.4s;
    margin: 0 5px;
    min-width: 56px;
    text-align: center;
  }
  
  .rbt-category a:hover {
    background-color: $color-grey;
  }
  
  
  .rbt-btn.btn-sm {
    padding: 0 22px;
    font-size: $font-size-b3;
    height: 45px;
    line-height: 43px;
    border-radius: 6px !important;
  }
  
  .rbt-btn.rbt-switch-btn {
    overflow: hidden;
  }
  .rbt-btn.btn-gradient {
    color: $color-white;
    background-size: 300% 100%;
    // background-image: linear-gradient(to right, #0c5460, #17a2b8, #17a2b8, #0c5460);
    background-image: linear-gradient(to right, $color-primary, $color-secondary, $color-secondary, $color-primary);
  }
  
  .rbt-btn {
    background: $color-primary;
    letter-spacing: 0.5px;
    font-weight: 500;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: all 0.4s ease-in-out;
    border: 0 none;
    outline: none;
  }
  
  .rbt-btn.rbt-switch-btn span {
    display: inline-flex;
    position: relative;
    z-index: 3;
    transition: opacity 0.65s, transform 0.85s;
    transition-timing-function: cubic-bezier(0.15, 0.85, 0.31, 1);
  }
  
  .rbt-btn.btn-gradient:hover {
    background-color: inherit;
    background-position: 102% 0;
    transition: all 0.4s ease-in-out;
  }
  
  .rbt-btn:hover.rbt-switch-btn span {
    transform: translateX(-200%);
  }
  
  .rbt-btn:hover.rbt-switch-btn span::after {
    opacity: 1;
    transform: translate(100%, -50%);
  }
  
  .rbt-btn.rbt-switch-btn span::after {
    content: attr(data-text);
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    top: 50%;
    opacity: 0;
    transition: inherit;
    left: 100%;
    transform: translate(50%, -50%);
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
  
    .rbt-card .rbt-card-body .rbt-card-title {
        font-size: 22px;
    }
  
    .rbt-card .rbt-card-body .rbt-card-text {
      margin-bottom: 14px;
    }
  
    .rbt-card.card-list-2 .rbt-card-body .rbt-card-title {
      font-size: 22px;
    }
  
    .rbt-card.card-list-2 .rbt-card-body {
      padding-left: 20px;
    }
  
    .rbt-card {
      padding: 20px;
    }
  }
  
  
  
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .rbt-course-grid-column .course-grid-3 {
        width: 50%;
    }
  
    .rbt-card .rbt-card-body {
      padding-top: 20px;
    }
  
    .rbt-card.card-list-2 {
      display: block;
    }
  
    .rbt-card.card-list-2 .rbt-card-img {
      height: auto;
    }
  
    .rbt-card.card-list-2 .rbt-card-body {
      padding-left: 0;
      padding-top: 30px;
    }
  }
  
  @media only screen and (max-width: 767px) {
  
    .rbt-card {
      padding: 15px;
    }
  
    .rbt-card .rbt-card-body {
      padding-top: 20px;
    }
  
    .rbt-card .rbt-card-body .rbt-card-title {
        font-size: 20px;
    }
  
    .rbt-card.card-list-2 .rbt-card-body .rbt-card-title {
      font-size: 20px;
    }
  
    .rbt-card .rbt-card-body .rbt-card-text {
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    .rbt-course-grid-column .course-grid-3 {
      width: 100%;
    }
  
    .rbt-card.card-list-2 {
      display: block;
    }
  
    .rbt-card.card-list-2 .rbt-card-img {
      height: auto;
    }
  
    .rbt-card.card-list-2 .rbt-card-body {
      padding-left: 0;
      padding-top: 30px;
    }
  }
  
  @media only screen and (max-width: 575px) {
    
    .rbt-card .rbt-card-body .rbt-card-title {
      font-size: 18px;
    }
    .rbt-card.card-list-2 .rbt-card-body .rbt-card-title {
        font-size: 20px;
    }
  
    .rbt-search-style input {
      min-width: auto;
    }
  
    .filter-select select {
      min-width: auto;
    }
  
  }
}
