@import '../../var.scss'; 


.learning-new-modalities {
  .rbt-cat-box-1 {
    display: block;
    transition: $transition;
  }
  
  .text-start {
    text-align: left!important;
  }

  a:hover, a:focus, a:active {
    text-decoration: none;
    outline: none;
    color: $color-primary;
  }
  
  .rbt-cat-box-1.variation-5 .inner {
    position: relative;
    z-index: 1;
    border-radius: 0;
    padding: 0;
  }
  
  .rbt-cat-box-1 .inner {
    background-color: $color-white;
    box-shadow: $shadow-1;
    transition: $transition;
  }
  
  .rbt-cat-box-1.variation-5 .inner .thumbnail a {
    display: block;
  }
  
  .rbt-cat-box-1.variation-5 .inner .thumbnail a img {
    border-radius: 6px;
  }
  
  img.custom-modalities-img {
    -ms-interpolation-mode: bicubic;
    border: 0;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
  }
  
  .rbt-cat-box-1.variation-5 .inner .content {
    display: flex;
    justify-content: space-between;
    padding: 20px 25px;
    position: absolute;
    z-index: 2;
    background: $color-white;
    left: 10px;
    bottom: 10px;
    right: 10px;
    border-radius: 6px;
  }
  
  .rbt-cat-box-1.variation-5 .inner .content .title {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.4;
    flex-basis: 90%;
    word-break: break-word;
    font-family: $font-primary;
    font-weight: $f-bold;
  }
  
  .title-link {
    color: black;
  }
  
  .rbt-cat-box-1.variation-5 .inner .content .read-more-btn {
    flex-basis: 10%;
  }
  
  .rbt-cat-box-1:hover {
    transform: translate3d(0, -10px, 0);
  }
}
