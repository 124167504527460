// @font-face {
//     font-family: 'Arimo';
//     src: url('./../assets/fonts/Arimo.eot');
//     src: url('./../assets/fonts/Arimo.woff2') format('woff2'),
//       url('./../assets/fonts/Arimo.woff') format('woff'),
//       url('./../assets/fonts/Arimo.eot?#iefix') format('embedded-opentype'),
//       url('./../assets/fonts/Arimo.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

.h6 {
    line-height: 1.65rem;
}

nav.navbar-public {
    .navbar-brand{
        cursor: pointer;
        font-size: $font-size-xl-lg;
        max-height: 3 * $navbar-brand-height;
        padding: 0;
        margin: 0;

        img {
            height: 2 * $navbar-brand-height;
        }

        sup{
            top: -2.5em;
            font-size: 0.25em;
        }
    }

    .form-inline{
        .form-control{
            width: auto;
        }
    }
}

.card{
    border: 0;

    figure{
        margin: 0 0 0rem;
    }

    .figure-caption{
        font-size:  100%;
    }

    .card-body{

        .card-text{
            font-size: 95%;

            .text-muted {
                margin-right: 4px;

                .badge{
                    font-size: 100%;
                }

                &::after{
                    content: '|';
                    padding-left: 4px;
                    font-size: 1rem;
                }

                &:last-child{
                    margin-right: 0px;

                    &::after{
                        content: ''
                    }
                }
            }
        }
    }
}

.list-group-item{
    cursor: pointer;
}

.w-36 {
    width: 36% !important;
}


@media(max-width:991px){
    nav{
        .navbar-brand{
            cursor: pointer;
            max-height: 1 * $navbar-brand-height;
            font-size: 1rem;
    
            img {
                height: 1 * $navbar-brand-height;
            }
        }
    }
}

@media(max-width:390px){
    nav{
        .navbar-brand{
            cursor: pointer;
            max-height: 1 * $navbar-brand-height;
            font-size: 1rem;
    
            img {
                height: 1 * $navbar-brand-height;
            }
        }

        .form-inline{
            width: 165px;

            .form-control {
                width: 115px;
            }
        }
    }
}

@include respond("sm") {
    .wm-100{
        width: 100% !important;
    }
}
