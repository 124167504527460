.grid-container{
    .grid-image-box{
        width: 175px;
        max-height: 225px;
        .grid-image{
            width: 100%;
        }
        .btn-block{
            opacity: 0
        }

        &:hover, &:focus , &:active
        {
            .btn-block
            {
                opacity: 1
            }
        }
    }
    .grid-video-box {
        width: 305px;
        max-height: 225px;
    }
}