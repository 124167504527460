.info .seminarName {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: #06425e;
    cursor :pointer;
}
.seminarName {
    margin-bottom: 0px !important;
}
.info .author {
    font-size: 11px;
    line-height: 25px;
    letter-spacing: 0.5px;
    color: #b2b2b2;
}
.seminar-detail {
    font-size: 12px;
    color: #070606;
    clear: both;
    display: block;
    margin: 0 !important;
    padding: 0 !important;
}
.ds-message .seminar-info .info {
    padding: 0px 0 13px 117px !important;
}
.info {
    padding: 0px 0 13px 117px !important;
}
.ds-message .seminar-info .avatar {
    position: absolute;
    width: 100px;
    height: 100px;
}
.avatar {
    position: absolute;
    width: 100px !important;
    height: 100px !important; 
    cursor: pointer;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.seminar-info {
    padding: 10px 0 5px;
    position: relative;
    border-bottom: #e9ecef 1px solid;

    &:last-child{
        border-bottom: 0;
    }
}
.seminar-title {
    cursor: pointer;
}

.seminarModal {
    max-height: 500px;
    overflow: auto;
}

.seminar-button.list-group-item-action{
    padding-left: 0;
    img{
        max-width: 100px;
    }
    &:hover, &:focus{
        background-color: #fff;
    }
}