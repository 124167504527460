/* .scroll{
    height:200px;
    width:750px;
    overflow:scroll;
    overflow-x:hidden;
    overflow-y:scroll
} */

.dashboard-image {
    max-width: 327px;
    max-height: 200px;
}