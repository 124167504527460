
.spinner-container{
    height: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;
    z-index: 9999;

    .sk-three-bounce {
        height: 25px;

        & > div{
            width: 25px;
            height: 25px;
        }
    }
}