.AutoCompleteText {
    width: 272px;
    // border: 1px solid grey;
    // box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 2px 4px 1px rgba(0,0,0,.18);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgba(0,0,0,0.73);
}
.AutoCompleteText input {
    width: 100%;
    // border: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgba(0,0,0,0.73);
    padding: 10px 5px;
    box-sizing: border-box;
    outline: none;

    &:focus{
        box-shadow:none;
    }
}

.AutoCompleteText ul {
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    max-height: 200px !important;
    overflow: auto;
    position: absolute;
    z-index: 1;
    background-color: white;
    width: 265px;
}

.AutoCompleteText ul::before {
    content: "";
}

.AutoCompleteText li {
    padding: 10px 5px;
    cursor: pointer;
}

.AutoCompleteText li:hover {
    text-decoration: underline;
    background-color: rgba(128, 128, 128, 0.20);
}
.img-responsive {
    width: 100%;  /* Make the image take the full width of the parent div */
    height: auto; /* Maintain the aspect ratio */
}