.login-page{
    
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            z-index: -1;
            background-image: url(../../assets/images/bg.jpg);
            background-size: cover;
            position: absolute;
            top: 0;
            right: 0;
        }
    
        .container {
            min-height: 100vh;
            z-index: 1;
            padding: 20px;

            
        }

        &.recovery {
            .container {
                max-height: 300px;

                .form-holder {

                    .form {

                        min-height: auto;
                        
                    }
                }
            }
        }
    
        .copyrights {
            width: 100%;
            z-index: 9999;
            position: absolute;
            bottom: 0;
            left: 0;
            color: #fff;
        }
    
        .form-holder {
            width: 100%;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 50px;
    
            .bg-info, .bg-event-che-secondary, .form {
                min-height: 70vh;
                padding: 40px;
                height: 100%;
            }
    
            div[class*='col-'] {
                padding: 0;
            }
        }

        @media (max-width: 991px) {
            .form-holder {
                margin-bottom: 0px;
                .bg-info, .bg-event-che-secondary, .form {
                    min-height: auto;
                    padding: 40px;
                    height: 100%
                }
            }
        }
    }

   