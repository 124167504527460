﻿.banner {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;


    img {
        max-width: 100%;
    }

    .btn {
        position: absolute;
        font-size: 20px;

        &.welcome {
            top: 10%;
            left: 10%;
        }

        &.benefit {
            left: 10%;
            top: 40%;
            max-width: 60%;
            word-break: break-word;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &.learning-process {
            bottom: 10%;
            // background: rgba(0,0,0,0.3);
            color: $white;
            border-color: $white;
        }

        &.learning-process-continue {
            bottom: 10%;
        }
    }
}

@include respond("md") {
    .banner {

        .btn {
            font-size: 13px;

            &.welcome {
                left: 5%;
            }

            &.benefit {
                left: 5%;
            }

        }
    }
}

@include respond("sm") {
    .banner {

        .btn {
            font-size: 12px;

            &.learning-process {
                bottom: 10%;
            }

            &.learning-process-continue {
                bottom: 10%;
            }
        }
    }
}

