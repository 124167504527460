.footer-page-banner-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;

  .footer-banner-image {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -4;
    background: linear-gradient(270deg, #2eabde 0%, #2c3f79 100%) !important;

   &::after {
      background: linear-gradient(#f7f8fa 0%, rgba(255, 255, 255, 0.1) 100%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      z-index: -1;
    }
  }

}

.mt--40 {
   margin-top: 40px !important;
 }

 .custom-footer-color {
  color: black;
 }

 a.custom-footer-link {
    color: black;

    &:hover {
      color: #0f6674;
    }
  }
// .custom-footer-background-wrapper {
//   display: flex;
//   justify-content: flex-start;
//   flex-direction: column;
//   position: relative;
//   margin: 0 auto;
//   padding: 0px 0px 170px;
//   width: 100%;
//   overflow: hidden;
  
//   #customFooterBackground {
//     background: linear-gradient(270deg, #2eabde 0%, #2c3f79 100%) !important;
//     display: block;
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     z-index: -4;
  
//     &::after {
//       background: linear-gradient(#f7f8fa 0%, rgba(255, 255, 255, 0.1) 100%);
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       content: "";
//       z-index: -1;
//     }
//   }
// }
